import Vue from "vue";
import App from "./App.vue";
// import router from "./router";
import store from "./store";

import Buefy from "buefy";
import "buefy/dist/buefy.css";

Vue.use(Buefy);

Vue.config.productionTip = false;

import axios from "axios";
var axiosInstance = axios.create({
	baseURL: process.env.MIX_API_URL || "http://127.0.0.1:8000",
});
Vue.prototype.$axios = axiosInstance;

new Vue({
	// router,
	store,
	render: function (h) {
		return h(App);
	},
}).$mount("#app");
