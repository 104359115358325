<template>
	<div>
		<footer class="footer has-background-light" style="padding: 20px 0;">
			<div class="container">
				<div class="level">
					<div class="level-left">
						<div class="level-item title is-6">&copy; {{ year }}, Springer Nature</div>
					</div>
					<div class="level-right">
						<div class="level-item">
							<div class="logo">
								<a class="is-link" href="https://www.springernature.com/" target="_blank">
									<b>Springer Nature</b>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
export default {
	data() {
		return {
			year: new Date().getFullYear(),
		};
	},
};
</script>

<style scoped>
.footer {
	color: #777;
}
</style>
