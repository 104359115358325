<template>
	<div id="app">
		<navbar />
		<section class="pa-10 f1">
			<div class="container">
				<!-- <router-view /> -->
				<home />
			</div>
		</section>
		<footbar></footbar>
	</div>
</template>

<script>
import navbar from "./components/navabr";
import footbar from "./components/footbar";
import home from "./views/Home";
export default {
	components: {
		navbar,
		footbar,
		home,
	},
};
</script>

<style>
#app {
	padding-top: 3.25rem;
	min-height: calc(100vh);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
	background: #ffffff;
}

.label {
	color: #777 !important;
	text-transform: capitalize;
}

.navbar {
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.flex,
.navbar {
	display: flex;
}
.w150 {
	font-weight: 400;
	font-size: 90%;
	width: 100px;
}
.val {
	margin-left: 20px;
}
.pa-10 {
	padding: 10px;
}
.mb-10 {
	margin-bottom: 10px;
}
.f1 {
	flex: 1;
}

.flex-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.vcenter {
	display: flex;
	align-items: center;
}
</style>
