<template>
	<div>
		<button class="button is-info is-small abs" @click="open">
			Update Address
		</button>
		<b-modal :active.sync="modal" has-modal-card :can-cancel="false">
			<form @submit.prevent="save">
				<div class="modal-card" style="width: 600px;" v-if="item">
					<header class="modal-card-head">
						<p class="title is-6">Update Address</p>
					</header>
					<section class="modal-card-body">
						<b-field label="House No" horizontal>
							<b-input v-model="item.hno" required> </b-input>
						</b-field>

						<b-field label="street" horizontal>
							<b-input v-model="item.street" required> </b-input>
						</b-field>

						<b-field label="district" horizontal>
							<b-input v-model="item.district" required> </b-input>
						</b-field>

						<b-field label="region" horizontal>
							<b-input v-model="item.region" required> </b-input>
						</b-field>
						<b-field label="city" horizontal>
							<b-input v-model="item.city" required> </b-input>
						</b-field>
						<b-field label="country" horizontal>
							<b-input v-model="item.country" required> </b-input>
						</b-field>
						<b-field label="zip" horizontal>
							<b-input v-model="item.zip" required> </b-input>
						</b-field>
					</section>
					<footer class="modal-card-foot">
						<div class="flex-bar" style="width: 100%;">
							<button class="button" type="button" @click="close">Close</button>
							<button class="button is-primary">Save Data</button>
						</div>
					</footer>
				</div>
			</form>
		</b-modal>
	</div>
</template>

<script>
export default {
	props: ["add"],
	data() {
		return {
			modal: false,
			item: null,
		};
	},
	methods: {
		open() {
			if (this.add) {
				this.item = this.add;
				this.modal = true;
			}
		},
		close() {
			this.modal = false;
			this.item = null;
		},
		save() {
			this.$buefy.snackbar.open(`Data Saved!`);
			this.$emit("saved");
			this.close();
		},
	},
};
</script>

<style scoped>
.abs {
	position: absolute;
	top: 5px;
	right: 5px;
}
</style>
