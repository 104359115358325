<template>
	<div class="home">
		<div class="card is-shadowless pa-10" v-if="data">
			<br />
			<h1 class="title is-5">Your Saved Addresses: {{ total || 0 }}</h1>
			<div class="card is-shadowless pa-10 has-background-light mb-10" v-for="d in data" :key="d.key">
				<Address :add="d.postal_address[0]" @saved="getData" />

				<div class="flex">
					<span class="has-text-grey w150">BP ID:</span>
					<span class="val"> {{ d.key }}</span>
				</div>
				<div class="flex">
					<span class="has-text-grey w150">
						Name:
					</span>
					<span class="val">
						{{ `${d.person[0].salutation} ${d.person[0].firstname} ${d.person[0].surname}` }}
					</span>
				</div>
				<div class="flex">
					<span class="has-text-grey w150">
						Email:
					</span>
					<span class="val">
						{{ d.email_address[0].email }}
					</span>
				</div>
				<div class="flex">
					<span class="has-text-grey w150">
						Address:
					</span>
					<span class="val">
						{{
							`${d.postal_address[0].hno || ""} ${d.postal_address[0].street || ""} ${d.postal_address[0].city || ""}
					${d.postal_address[0].country || ""} ${d.postal_address[0].zip || ""}`
						}}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Address from "../components/Address.vue";

export default {
	name: "Home",
	components: { Address },
	data() {
		return {
			data: null,
			total: 0,
			email: null,
		};
	},
	created() {
		let email = document.head.querySelector('meta[name="user-email"]');
		if (email) {
			this.email = email;
			this.getData(email.content);
		} else {
			this.$buefy.snackbar.open(`Please log in to proceed!`);
			window.location.href = window.location.pathname.replace("home", "login") || "/login";
		}
	},
	methods: {
		async getData(query) {
			let loader = this.$buefy.loading.open();
			if (!query) query = this.email;
			try {
				const { data } = await this.$axios.post("/search", { query });
				this.total = data.total;
				this.data = data.data.bpSearch;
			} catch (error) {
				alert(error);
			}
			loader.close();
		},
	},
};
</script>
