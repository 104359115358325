<template>
	<div>
		<nav class="navbar is-fixed-top has-background-light">
			<div class="flex-bar" style="flex: 1; margin: auto 1.5em;">
				<div class="navbar-item">
					<img src="https://www.springernature.com/app-sn/public/images/logo-springernature.svg" />
				</div>
				<!-- <div class="flex">
					<router-link class="navbar-item" to="/">
						Home
					</router-link>
					<router-link class="navbar-item" to="/about">
						About
					</router-link>
				</div> -->
			</div>
		</nav>
	</div>
</template>

<script>
export default {};
</script>
